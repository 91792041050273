import { useState } from "react";

const portfolioItems = [
  {
    name: "Skybaby Animation Reel",
    link: "https://youtube.com/embed/v6hsXq-QqlE?autoplay=1",
  },
  {
    name: "School Lunch (Short)",
    link: "https://youtube.com/embed/k4_CxbY3fMw",
  },
  {
    name: "Gone By Dierks Bentley",
    link: "https://www.youtube.com/embed/lFgQS11V4Co",
  },
  {
    name: "Blue Dream Baby By Fancy Hagood and Kacey Musgraves",
    link: "https://www.youtube.com/embed/kai9cigvcwA",
  },
  {
    name: "Bored By Fancy Hagood",
    link: "https://youtube.com/embed/kOM-sXtJytw",
  },
  {
    name: "TV Time (Short)",
    link: "https://youtube.com/embed/jhrZhxI3RQA",
  },
  {
    name: "In the Jailhouse Now By Old Crow Medicine Show",
    link: "https://youtube.com/embed/s2OKsM6UaW0",
  },
  {
    name: "Drinkin' With Ringo",
    link: "https://youtube.com/embed/44-BN8x2BjU",
  },
  {
    name: '"Video Chat" By Skylar Wilson',
    link: "https://youtube.com/embed/attjMJ0cBEc",
  },
];

// .all-work > a {
//   display: block;
//   margin-bottom: 12px;
// }

const Portfolio: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [currentItem, setCurrentItem] = useState(portfolioItems[0]);
  const [showAllWork, setShowAllWork] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <iframe
          title="selected-portfolio-item"
          style={{ marginRight: 45, marginLeft: 45 }}
          height="275"
          src={currentItem.link}
        />
      </div>
      {isMobile ? null : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 12,
          }}
        >
          {portfolioItems.map(({ name, link }, key) => (
            <a
              key={key}
              onClick={() => setCurrentItem({ name, link })}
              style={{ marginBottom: 12 }}
              href="/#"
            >
              {name}
            </a>
          ))}
        </div>
      )}
    </>
  );
};

export default Portfolio;
