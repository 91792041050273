import { useEffect, useState } from "react";
import Portfolio from "../portfolio";

const isMobile = () =>
  window.innerWidth < 768 ||
  navigator.userAgent.indexOf("Mobi") > -1;

const Main = () => {
  const [mobileView, setMobileView] = useState<boolean>(isMobile());

  useEffect(() => {
    function handleResize() {
      setMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="master-container">
      <header className="main-header">
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            height={350}
            style={{ marginTop: -145, marginLeft: -100, marginBottom: -125 }}
            src="images/logo.png"
          />
        </div>
        {mobileView ? <Portfolio isMobile /> : null}

        <nav className="navbar"></nav>
      </header>
      <div
        style={{
          display: "flex",
          flexDirection: mobileView ? "column" : "row",
          justifyContent: "center",
          alignItems: mobileView ? "center" : "flex-start",
        }}
      >
        <aside className="profile-sidebar">
          <header>
            <h1>Skylar Wilson</h1>
          </header>
          <div className="profile-picture-container">
            <img
              className="profile-pic"
              src="images/profile.jpg"
              alt="Skylar, the creator of SkySpace."
              height={150}
              width={150}
            />

            <p className="personal-msg">
              <b>"Skybaby Cartoons"</b>
            </p>

            <ul className="gender-age">
              <li>Male</li>
              <li>125 years old</li>
              <li>Nashville,</li>
              <li>Tennessee</li>
              <li>United States</li>
              <div className="online-now">Online Now!</div>
            </ul>

            <ul className="last-login">
              <li>Last Login:</li>
              <li>12/25/2079</li>
            </ul>
          </div>
          <section className="myspace-url-box">
            <h3>
              <b>FOR ALL INQUIRIES:</b>
            </h3>
            <p style={{ marginLeft: -3, paddingTop: 12 }}>
              email wilson.skylar@gmail.com
            </p>
          </section>
          <section className="interests">
            <table className="sidebar-table">
              <caption>
                <h2 className="sidebar-table-h2">Skylar's Interests</h2>
              </caption>

              <tbody>
                <tr>
                  <th className="sidebar-table-header" scope="row">
                    General
                  </th>
                  <td className="sidebar-table-data">
                    <p>
                      Cartoons, music, movies, junk food, history, and fishing.
                      And friends and family.
                    </p>
                  </td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Music
                  </th>
                  <td className="sidebar-table-data">
                    <p>
                      <b>Genres</b>: Country, rock, blues, jazz and pop.
                    </p>
                  </td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Movies
                  </th>
                  <td className="sidebar-table-data">
                    <p>
                      <b>Films</b>: most anything 80's/90's.
                    </p>

                    <p>
                      <b>Directors</b>: Tarantino, Spielberg, Scorcese
                    </p>
                  </td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Television
                  </th>
                  <td className="sidebar-table-data">
                    <p>I have a big t.v.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="details">
            <table className="sidebar-table">
              <caption>
                <h2 className="sidebar-table-h2">Skylar's Details</h2>
              </caption>

              <tbody>
                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Status:
                  </th>
                  <td className="sidebar-table-data">Awesome</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Here For:
                  </th>
                  <td className="sidebar-table-data">Serious Relationships</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Hometown:
                  </th>
                  <td className="sidebar-table-data">Memphis, TN</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Ethnicity:
                  </th>
                  <td className="sidebar-table-data">White/Caucasion</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Zodiac Sign:
                  </th>
                  <td className="sidebar-table-data">Capricorn</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Smoke / Drink:
                  </th>
                  <td className="sidebar-table-data">Do it</td>
                </tr>

                <tr>
                  <th className="sidebar-table-header" scope="row">
                    Occupation:
                  </th>
                  <td className="sidebar-table-data">Cartoonist</td>
                </tr>
              </tbody>
            </table>
          </section>
        </aside>
        <main>
          <section className="user-network">
            <header>
              <h2>Skylar is in your extended network</h2>
            </header>
          </section>
          <Portfolio isMobile={false} />
          <section className="blurbs">
            <header className="main-section-header">
              <h2 className="main-section-h2">Skylar's Blurbs</h2>
            </header>

            <h3>About me:</h3>
            <p>
              Hi! I'm Skylar Wilson from Nashville, TN. I am the capitan of
              Skybaby Cartoons and we specialize in cartoons and 2D animations.
              I've been lucky enough to work with some of the most talented
              music and visual artists in Nashville and beyond. I've been a
              finalist in several animation festivals, and was lucky to be a
              part of a Peabody Award-winning short! That was real cool.
            </p>
          </section>

          <section className="friends">
            <header className="main-section-header">
              <h2 className="main-section-h2">Skylar's Client Space</h2>
            </header>

            <p>
              <b>
                Skylar has <span className="focus-highlight">some recent</span>{" "}
                Clients.
              </b>
            </p>

            <div
              className="friend-pic-container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                rowGap: 24,
              }}
            >
              <div>
                <figcaption>
                  <a href="#">Netflix</a>
                </figcaption>
                <img src="images/netflix.png" alt="" height={80} />
              </div>

              <div>
                <figcaption>
                  <a href="#">CMT</a>
                </figcaption>
                <img
                  src="images/cmt.png"
                  alt=""
                  style={{ marginTop: 8 }}
                  height={60}
                />
              </div>

              <div>
                <figcaption>
                  <a href="#">Warner Bros.</a>
                </figcaption>
                <img
                  src="images/warner_bros.png"
                  alt=""
                  style={{ marginTop: 8 }}
                  height={60}
                />
              </div>

              <div>
                <figcaption>
                  <a href="#">Dierks Bentley</a>
                </figcaption>
                <img src="images/dierks.jpeg" alt="" height={80} />
              </div>

              <div>
                <figcaption>
                  <a href="#">Midland</a>
                </figcaption>
                <img src="images/midland.webp" alt="" height={80} />
              </div>

              <div>
                <figcaption>
                  <a href="#">Fancy Hagood</a>
                </figcaption>
                <img src="images/fancy.webp" alt="" height={80} width={100} />
              </div>

              <div>
                <figcaption>
                  <a href="#">Kacey Musgraves</a>
                </figcaption>
                <img src="images/kacey.webp" height={80} />
              </div>

              <div>
                <figcaption>
                  <a href="#">Old Crow Medicine Show</a>
                </figcaption>
                <img src="images/old_crow.jpeg" alt="" height={80} />
              </div>
            </div>

            <p className="friends-list-link">
              {/* <a href="#">View All of Skylar's Friends</a> */}
            </p>
          </section>
        </main>
      </div>
      <footer className="footer">
        <small>
          ©{new Date().getFullYear()} skybabycartoons.com. All Rights Reserved.
        </small>
      </footer>
    </div>
  );
};

export default Main;
